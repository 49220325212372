<template>
  <q-layout view="hHh lpR fFf" class="app_bg canna_pd_Page">

    <q-header class="canna_header_bg noselect">
      <q-toolbar>

        <template v-if="(pageSize === 'xs' || pageSize === 'sm') ? true : false">
          <q-btn dense flat round icon="menu" class="canna_header lt-md" @click="drawerLeft = !drawerLeft" />
        </template>

        <q-toolbar-title class="header_title" style="display: flex;justify-content: flex-start;">
          <div class="action_item_generic" @click.stop="goToMainPage">Canna</div>
        </q-toolbar-title>

        <template v-if="profile_foto!==null">
          <q-avatar class="app_avatar_generic app_avatar_generic_24" style="margin-right: 6px;">
            <img :src="'/personas/persona_2.jpg'">
          </q-avatar>
        </template>
        <template v-else>
          <q-avatar class="app_avatar_generic app_avatar_generic_24 headerAvatar_text" style="margin-right: 6px;">
            {{profile_abrev}}
          </q-avatar>
        </template>

        <q-icon name="expand_more" class="actionIcon canna_header_icon" style="margin-right: 12px;">
            <q-menu anchor="bottom middle" self="top end">
              <q-item clickable v-close-popup @click.stop="goToConfigPage">
                <q-item-section>Configuración</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click.stop="goToCloseSession">
                <q-item-section>Cerrar sesión</q-item-section>
              </q-item>
            </q-menu>
        </q-icon>

        <whatsapp-button size="30px" />
        <!-- 
          <cxIcon 
            iconType="bell"
            size="24px"
            class="xxx-demo zzz-prueba"
            :cls="'icon_svg_off'"
            :overCls="'icon_svg_offB'"
            :state="0"
            :hasMouseOver="true"
            :hasAction="true"
            :allowDisabled="true"
            :allowClick="true"
            @iconClick="onClickHeaderBell"
          >
          </cxIcon> 
        -->

      </q-toolbar>
    </q-header>

    <q-drawer v-model="drawerLeft" :width="192" show-if-above content-class="canna_drawer" v-if="useSideBarHnD">

      <q-scroll-area style="height: calc(100% - 0px);"><!-- margin-top: 56px; -->

        <q-list>
          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="($route.name==='dashboard_main_page')"
            :to="'/dashboard'"
            :exact="true"
          >
            <div class="planmenu_pointer" v-if="($route.name==='dashboard_main_page')">&nbsp;</div>
            <q-item-section>Inicio</q-item-section>
            <q-item-section avatar>
              <cxIcon 
                iconType="cannav2_menu_start"
                size="24px"
                class="xxx-demo zzz-prueba"
                :cls="'canna_menuIcon'"
                :state="($route.name==='dashboard_main_page') ? 1 : 0"
                :hasMouseOver="true"
                :hasAction="true"
                :allowDisabled="true"
                :allowClick="false"
              />
              <!-- @iconClick="goPage('dashboard')" -->
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="(isPersonalRoute($route.name)===true)"
            :to="questionarioPath"
            :exact="true"
            style="align-items: center;"
          >
            <div class="planmenu_pointer" v-if="(isPersonalRoute($route.name)===true)">&nbsp;</div>
            <q-item-section>
              Información personal
            </q-item-section>
            
            <template v-if="(profileComplete===false)">
              <q-item-section side>
                <div style="display: flex;">
                  <q-circular-progress
                    :min="0"
                    :max="100"
                    :value="profileProgress"
                    size="20px"
                    :thickness="progressthick"
                    :color="(isPersonalRoute($route.name)===true) ? 'cannaprogressActive' :'cannaprogress'"
                    track-color="cannaprogressBG"
                  />
                  <div class="progressText" style="margin-left: 5px;"
                    :class="{'progressTextActive':(isPersonalRoute($route.name)===true) ? true : false}"
                  >
                    {{profileProgress}}%
                  </div>
                </div>
              </q-item-section>
            </template>
            <template v-else>
              <q-item-section avatar>
                <cxIcon 
                  iconType="cannav2_menu_complete"
                  size="24px"
                  class="xxx-demo zzz-prueba"
                  :cls=" (isPersonalRoute($route.name)===true) ?  'canna_menuIcon_active' : 'canna_menuIcon' "
                  :state="($route.name==='dashboard_personal_page') ? 1 : 0"
                  :hasMouseOver="true"
                  :hasAction="true"
                  :allowDisabled="true"
                  :allowClick="false"
                />
                <!-- @iconClick="goPage('personalinfo')" -->
              </q-item-section>
            </template>

          </q-item>

          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="($route.name==='dashboard_documents_page')"
            :to="'/dashboard/docs'"
            :exact="true"
          >
            <div class="planmenu_pointer" v-if="($route.name==='dashboard_documents_page')">&nbsp;</div>
            <q-item-section>Documentos</q-item-section>
            <q-item-section avatar>
              <cxIcon 
                iconType="cannav2_menu_docs"
                size="24px"
                class="xxx-demo zzz-prueba"
                :cls="'canna_menuIcon'"
                :state="($route.name==='dashboard_documents_page') ? 1 : 0"
                :hasMouseOver="true"
                :hasAction="true"
                :allowDisabled="true"
                :allowClick="false"
              />
              <!-- @iconClick="goPage('documents')" -->
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple
            tag="a"
            class="planMenuItem"
            active-class="planMenu_active"
            :active="($route.name==='dashboard_videos_page')"
            :to="'/dashboard/videos'"
            :exact="true"
          >
            <div class="planmenu_pointer" v-if="($route.name==='dashboard_videos_page')">&nbsp;</div>
            <q-item-section>Videos</q-item-section>
            <q-item-section avatar>
              <cxIcon 
                iconType="cannav2_menu_videos"
                size="24px"
                class="xxx-demo zzz-prueba"
                :cls="'canna_menuIcon'"
                :state="($route.name==='dashboard_videos_page') ? 1 : 0"
                :hasMouseOver="true"
                :hasAction="true"
                :allowDisabled="true"
                :allowClick="false"
              />
              <!-- @iconClick="goPage('videos')" -->
            </q-item-section>
          </q-item>

          <!--  MENU vacantes dev-sprint12 may 2022 -->
          <template v-if="(profile_status >= zstatUser.REDES_SOCIALES && profile_status !== zstatUser.CONTRATADO )">
            <q-item clickable v-ripple
              tag="a"
              class="planMenuItem"
              active-class="planMenu_active"
              :active="($route.name==='dashboard_vacantes_page' || $route.name==='dashboard_vacantesdetail_page')"
              :to="'/dashboard/vacantes'"
              :exact="true"
            >
              <div class="planmenu_pointer" v-if="($route.name==='dashboard_vacantes_page' || $route.name==='dashboard_vacantesdetail_page')">&nbsp;</div>
              <q-item-section>Vacantes</q-item-section>
              <q-item-section avatar>
                <cxIcon 
                  iconType="cannav2_menu_vacantes"
                  size="24px"
                  class="xxx-demo zzz-prueba"
                  :cls="'canna_menuIcon'"
                  :state="($route.name==='dashboard_vacantes_page' || $route.name==='dashboard_vacantesdetail_page') ? 1 : 0"
                  :hasMouseOver="true"
                  :hasAction="true"
                  :allowDisabled="true"
                  :allowClick="false"
                />
                <!-- @iconClick="goPage('videos')" -->
              </q-item-section>
            </q-item>
          </template>

          <template v-if="111===444">
            <q-item clickable v-ripple
              tag="a"
              class="planMenuItem"
              active-class="planMenu_active"
              :active="($route.name==='dashboard_solicitudes_page')"
              :to="'/dashboard/solicitudes'"
              :exact="true"
              v-if="(current_profile.trab_id !== undefined && current_profile.trab_id!== null && current_profile.trab_id!=='')"
            >
              <div class="planmenu_pointer" v-if="($route.name==='dashboard_solicitudes_page')">&nbsp;</div>
              <q-item-section>Solicitudes</q-item-section>
              <q-item-section avatar>
                <cxIcon 
                  iconType="cannav2_menu_solicitud"
                  size="24px"
                  class="xxx-demo zzz-prueba"
                  :cls="'canna_menuIcon'"
                  :state="($route.name==='dashboard_solicitudes_page') ? 1 : 0"
                  :hasMouseOver="true"
                  :hasAction="true"
                  :allowDisabled="true"
                  :allowClick="false"
                />
                <!-- @iconClick="goPage('videos')" -->
              </q-item-section>
            </q-item>
          </template>

        </q-list>

      </q-scroll-area>

    </q-drawer>

    <template v-if="initScreen === true">
      <q-page-container id="canna_pl_pageContainer" class="canna_pd_Page">
        <keep-alive>
          <router-view />
        </keep-alive>
      </q-page-container>
    </template>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: -51px;z-index: 9000;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: -51px;z-index: 9000;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>

  </q-layout>
</template>
<script>
import { requestPack } from './../boot/axios' // APP code
import { canna_cookieMethods } from './../boot/__cannav2_app' // Cookies method 

import myplatform_Mixin from './../mixins/platformMix.js'
import logOut_Mixin from './../mixins/requestMix.js'
import helperBase_Mixin from './../mixins/helperBase.js'

export default {
  name: 'dashboard_main_layout',
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components: {
    cxIcon: require('components/appIcons.vue').default,
    whatsappButton: require('components/whatsapp-button.vue').default
  },
  data () {
    return {
      a_name: 'layout dashboard canna 201',
      version: '201',
      useDrawer: false,
      drawerLeft: false,
      initScreen: false,
      loading_Process:false, // Orbit 
      zloadingData: false, // Gears 
      info: {
        data: []
      },
      progressthick: 0.35,
      profileComplete: false, // Temporales
      questionarioPath: '/',
      profileProgress: 2, // Temporales
      rutasRefs:[ // PAra referencia de los estatus ver  updatePersonalProgress
        { id: 1, name: 'dashboard_pi_foto', pctVal: 0, estatusMin: 12, estatusReal: 13, path:'/dashboard/personal' },
        { id: 2, name: 'dashboard_pi_puestos', pctVal: 8, estatusMin: 13, estatusReal: 14, path: '/dashboard/personal/trabajo-b' },
        { id: 3, name: 'dashboard_pi_sueldos', pctVal: 17, estatusMin: 14, estatusReal: 15, path: '/dashboard/personal/trabajo-c' },
        { id: 4, name: 'dashboard_pi_horario', pctVal: 22, estatusMin: 15, estatusReal: 16, path: '/dashboard/personal/trabajo-d' },
        { id: 5, name: 'dashboard_pi_direccion', pctVal: 26, estatusMin: 16, estatusReal: 17, path: '/dashboard/personal/direccion-a'},
        { id: 6, name: 'dashboard_pi_pais', pctVal: 35, estatusMin: 17, estatusReal: 18, path:'/dashboard/personal/direccion-b' },
        { id: 7, name: 'dashboard_pi_licencia', pctVal: 39, estatusMin: 18, estatusReal: 19, path:'/dashboard/personal/direccion-c'},
        { id: 8, name: 'dashboard_pi_tieneauto', pctVal: 48, estatusMin: 19, estatusReal: 20, path: '/dashboard/personal/direccion-d' },
        { id: 9, name: 'dashboard_pi_medidas', pctVal: 65, estatusMin: 20, estatusReal: 21, path: '/dashboard/personal/uniformes-a' },
        { id: 10, name: 'dashboard_pi_escuela', pctVal: 78, estatusMin: 21, estatusReal: 22, path: '/dashboard/personal/aptitudes-a' },
        { id: 11, name: 'dashboard_pi_canales', pctVal: 83, estatusMin: 22, estatusReal: 23, path: '/dashboard/personal/aptitudes-b' },

        { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 78, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' }, //depende de Pantalla anterior (tiene xp si/no)
        { id: 13, name: 'dashboard_pi_curriculum', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        { id: 14, name: 'dashboard_pi_sociales', pctVal: 90, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }

        // NO tiene EXP 
        // ----- { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 87, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' },  (SALTAR)
        // { id: 13, name: 'dashboard_pi_curriculum', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        // { id: 14, name: 'dashboard_pi_sociales', pctVal: 90, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        // { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }
        
				// SI tIENE EXP 
				// { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 87, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' },
				// NOTA: si el estatus es >24  checar si hay 1 o 3 registros de empleo si no lo tiene Restarle 5 al porcentaje actual 
        // { id: 13, name: 'dashboard_pi_curriculum', pctVal: 92, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        // { id: 14, name: 'dashboard_pi_sociales', pctVal: 96, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        // { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }
      ]
    }
  },
  computed: {
    appLogin () {
      return this.$store.state.myapp.appHasLogin
    },
    estatusx_USR () { 
      return this.$cannaEstatus.usuario 
    },
    useSideBarHnD () {
      var rutan = this.$route.name 
      var nouseHd = [ ]
      return (nouseHd.indexOf(rutan) >= 0) ? false : true
    },
    current_profile (){
      return this.getProfileData
    },
    profile_foto () {
      if (this.$store.state.myapp.appUserProfile.hasOwnProperty('imagenFoto')){ 
        return this.$store.state.myapp.appUserProfile.imagenFoto
      } else { 
        return null
      }
    },
    profile_abrev () {
      return this.$store.state.myapp.appUserProfile.abreviatura || 'CM' 
    }, 
    profile_status (){
      return parseInt(this.getProfileData.status)
    },
    myRouteRef (){
      return this.$route.name 
    },
    current_profile_Experiencia (){
      try {
        if (this.current_profile._embedded.userPerfilLaboralFiltro){ 
          if (this.current_profile._embedded.userPerfilLaboralFiltro.length>0){ 
            var experieciaData = this.current_profile._embedded.userPerfilLaboralFiltro.filter(item=> item.categoriaId===14 && item.catalogo_id === 99)
            return (experieciaData.length>0) ? true : false
          } else {
            return false 
          }
        } else { 
          return false 
        }
      } catch (ex) {
        return false 
      }
    }
  },
  watch: {
    myRouteRef: function (newVal, oldVal){
      if (newVal !== oldVal){
        this.updatePersonalProgress()
      }
    },
    profile_status: function (newVal, oldVal){
      if (newVal !== oldVal){
        this.updatePersonalProgress()
      }
    },
    current_profile_Experiencia (newVal, oldVal){
      if (newVal !== oldVal){
        this.updatePersonalProgress()
        this.$forceUpdate()
      }
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods, 
    onClickHeaderBell: function () { 
      this.$cannaDebug('-- PV2 -- Main Layout -- bell clicked')
    },
    goToMainPage () { 
      var newRuta = '/dashboard'
      setTimeout(() => {
        this.$router.push(newRuta)
      }, 150)
    },
    goToConfigPage (){
     var newRuta = '/settings'
      setTimeout(() => {
        this.$router.push(newRuta)
      }, 150)
    },
    goToCloseSession (){
      this.$cannaDebug('-- PV2 -- Main Layout -- go To CloseSession')
      var test = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2Public' )
      this.$cannaDebug('-- PV2 -- Main Layout -- goToCloseSession --- ' + test)
      window.location = '/'
    },
    goto_PersonalPage(){
     var newRuta = this.questionarioPath
      setTimeout(() => {
        this.$router.push(newRuta)
      }, 150)
    },
    isPersonalRoute (routeName){ 
      var test = 11
      var indexRutaPersonal = null 
      indexRutaPersonal  = this.rutasRefs.findIndex(item => item.name === routeName)
      return (indexRutaPersonal>=0) ? true: false 
    },
    updatePersonalProgress () { 
      this.$cannaDebug('-- PV2 -- Main Layout -- update Personal Progress')
      var test = 11
      var newValuePct = null 
      var indexRutaPersonal = null 
      var indexEstatus = null
      // NOTAS del estatus 
      /*
        Conforme al API/BDD estos son estos los estatus 

          STATUS_DELETED = 0;
          STATUS_INACTIVE = 9;
          STATUS_ACTIVE = 10; //Registro de un nuevo usuario desde frontend/api
          STATUS_DATOS_PERSONALES = 11;
          STATUS_DATOS_LEGALES = 12;
          STATUS_FOTO = 13;
          STATUS_PUESTOS = 14;
          STATUS_SUELDO = 15;
          STATUS_JORNADA = 16;
          STATUS_DIRECCION = 17;
          STATUS_PAIS = 18;
          STATUS_LICENCIA = 19;
          STATUS_TIENES_AUTO = 20;
          STATUS_MEDIDAS = 21;
          STATUS_ESCOLARIDAD = 22;
          STATUS_CANALES_EXPERIENCIA = 23;
          STATUS_LABORAL_EXPERIENCIA = 24; --> dev-sprint12 
          STATUS_CURRICULUM = 24; ---> 25   --> dev-sprint12 
          STATUS_REDES_SOCIALES = 25; ---> 26  --> dev-sprint12 
          STATUS_PREVIEW = 26;	---> 27  --> dev-sprint12 
          STATUS_COMPLETO = 27;		---> 28  --> dev-sprint12 
          STATUS_CONTRATADO = 28;	---> 29  --> dev-sprint12 
          STATUS_NO_DESEADO = 29; ---> 30  --> dev-sprint12 

        Se determina los siguentes porcentajes :  
          (pctVal =  PORCENTAJE de AVANCE )  
          (estatusMin = Es el Estatus q tiene el registro)
          (estatusReal = Al estatus q se asigna al avanzar en la pantalla)

          Ejemplo : 

          EJ 1 (paso 1): { id: 1, name: 'dashboard_pi_foto', pctVal: 2, estatusMin: 12, estatusReal: 13, path:'/dashboard/personal' },  
            --- Aqui la persona (la 1a vez q se registra) tiene estatus 12 cuando pone la foto y AVANZA a la siguiente pantalla el estatus cambia a 13  
              por lo cual al tener estatus 13 el pct de la persona seria 4% 

          EJ 2 (paso 2): { id: 2, name: 'dashboard_pi_puestos', pctVal: 4, estatusMin: 13, estatusReal: 14, path: '/dashboard/personal/trabajo-b' },
            --- Aqui la persona (la 1a vez q se registra) tiene estatus 13 (situado en pantalla para q puestos aplica) cuando llena la forma y avanza el estatus cambia a 14 
              por lo cual al tener estatus 14 el pct de la persona seria 8% 


        Referencias de las pantallas :: 

        { id: 1, name: 'dashboard_pi_foto', pctVal: 2, estatusMin: 12, estatusReal: 13, path:'/dashboard/personal' },
        { id: 2, name: 'dashboard_pi_puestos', pctVal: 4, estatusMin: 13, estatusReal: 14, path: '/dashboard/personal/trabajo-b' },
        { id: 3, name: 'dashboard_pi_sueldos', pctVal: 8, estatusMin: 14, estatusReal: 15, path: '/dashboard/personal/trabajo-c' },
        { id: 4, name: 'dashboard_pi_horario', pctVal: 17, estatusMin: 15, estatusReal: 16, path: '/dashboard/personal/trabajo-d' },
        { id: 5, name: 'dashboard_pi_direccion', pctVal: 22, estatusMin: 16, estatusReal: 17, path: '/dashboard/personal/direccion-a'},
        { id: 6, name: 'dashboard_pi_pais', pctVal: 26, estatusMin: 17, estatusReal: 18, path:'/dashboard/personal/direccion-b' },
        { id: 7, name: 'dashboard_pi_licencia', pctVal: 35, estatusMin: 18, estatusReal: 19, path:'/dashboard/personal/direccion-c'},
        { id: 8, name: 'dashboard_pi_tieneauto', pctVal: 39, estatusMin: 19, estatusReal: 20, path: '/dashboard/personal/direccion-d' },
        { id: 9, name: 'dashboard_pi_medidas', pctVal: 48, estatusMin: 20, estatusReal: 21, path: '/dashboard/personal/uniformes-a' },
        { id: 10, name: 'dashboard_pi_escuela', pctVal: 65, estatusMin: 21, estatusReal: 22, path: '/dashboard/personal/aptitudes-a' },
        { id: 11, name: 'dashboard_pi_canales', pctVal: 78, estatusMin: 22, estatusReal: 23, path: '/dashboard/personal/aptitudes-b' },

        { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 78, estatusMin: 22, estatusReal: 23, path: '/dashboard/personal/aptitudes-c' }, --> dev-sprint12 

        { id: 13, name: 'dashboard_pi_curriculum', pctVal: 83, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-d' }, --> dev-sprint12  (cambia valor dependiendo de 11 y 12)
        { id: 14, name: 'dashboard_pi_sociales', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/mediossociales' }, --> dev-sprint12 (cambia valor dependiendo de 11 y 12)
        ::: pantalla final = 
          --- REsumen de informacion, en esta pantalla cuando carga , determina y valida todas las secciones y estatus de la persona
          --- si todo esta completado entonces actualiza el estatus de la persona a 26 el cual significaria que la la persona esta en un 100% de completo en captura de informacion 
        { id: 15, name: 'dashboard_pi_review', pctVal: 96, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/revision' } --> dev-sprint12 (cambia valor dependiendo de 11 y 12)
        --------------------------
        --------------------------
        --- antes del dev-sprint12
        { id: 12, name: 'dashboard_pi_curriculum', pctVal: 83, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-d' },
        { id: 13, name: 'dashboard_pi_sociales', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/mediossociales' },
        ::: pantalla final = 
          --- REsumen de informacion, en esta pantalla cuando carga , determina y valida todas las secciones y estatus de la persona
          --- si todo esta completado entonces actualiza el estatus de la persona a 26 el cual significaria que la la persona esta en un 100% de completo en captura de informacion 
        { id: 14, name: 'dashboard_pi_review', pctVal: 96, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/revision' }
      */

      if (this.profile_status>=this.zstatUser.PREVIEW){
        newValuePct = 100
      } else {

          // NOTA dev-sprint12 
          // Revisar que si el estatus es >= 23 si tiene Experiencia -> EMpleos Se modificaria el PCT de acuerdo AAAAAA 
          if (this.profile_status>=this.zstatUser.CANALES_EXPERIENCIA){
            if (this.current_profile._embedded.userPerfilLaboralFiltro){ 
              if (this.current_profile._embedded.userPerfilLaboralFiltro.length>0){ 
                var experieciaData = this.current_profile._embedded.userPerfilLaboralFiltro.filter(item=> item.categoriaId===14 && item.catalogo_id === 99)
                if (experieciaData.length>0) { //Si tiene experiencia  //Checamos los empleos (registros)
                  var indexUpdate = this.rutasRefs.findIndex(itemxx=> itemxx.id===12)
                  if (indexUpdate>=0){
                    this.rutasRefs[indexUpdate].pctVal = 87
                    this.rutasRefs[indexUpdate].estatusMin = 23
                    this.rutasRefs[indexUpdate].estatusReal = 24
                  }

                  var indexUpdateb = this.rutasRefs.findIndex(itemxx=> itemxx.id===13)
                  if (indexUpdateb>=0){
                    this.rutasRefs[indexUpdateb].pctVal = 92
                    this.rutasRefs[indexUpdateb].estatusMin = 24
                    this.rutasRefs[indexUpdateb].estatusReal = 25
                  }

                  var indexUpdatec = this.rutasRefs.findIndex(itemxx=> itemxx.id===14)
                  if (indexUpdatec>=0){
                    this.rutasRefs[indexUpdatec].pctVal = 96
                    this.rutasRefs[indexUpdatec].estatusMin = 25
                    this.rutasRefs[indexUpdatec].estatusReal = 26
                  }

                  var indexUpdated = this.rutasRefs.findIndex(itemxx=> itemxx.id===15)
                  if (indexUpdated>=0){
                    this.rutasRefs[indexUpdated].pctVal = 100
                    this.rutasRefs[indexUpdated].estatusMin = 26
                    this.rutasRefs[indexUpdated].estatusReal = 27
                  }
                } else {  // No tiene experiencia 

                  var indexUpdate = this.rutasRefs.findIndex(itemxx=> itemxx.id===12)
                  if (indexUpdate>=0){
                    this.rutasRefs[indexUpdate].pctVal = 87
                    this.rutasRefs[indexUpdate].estatusMin = 23
                    this.rutasRefs[indexUpdate].estatusReal = 24
                  }

                  var indexUpdateb = this.rutasRefs.findIndex(itemxx=> itemxx.id===13)
                  if (indexUpdateb>=0){
                    this.rutasRefs[indexUpdateb].pctVal = 87
                    this.rutasRefs[indexUpdateb].estatusMin = 24
                    this.rutasRefs[indexUpdateb].estatusReal = 25
                  }

                  var indexUpdatec = this.rutasRefs.findIndex(itemxx=> itemxx.id===14)
                  if (indexUpdatec>=0){
                    this.rutasRefs[indexUpdatec].pctVal = 90
                    this.rutasRefs[indexUpdatec].estatusMin = 25
                    this.rutasRefs[indexUpdatec].estatusReal = 26
                  }

                  var indexUpdated = this.rutasRefs.findIndex(itemxx=> itemxx.id===15)
                  if (indexUpdated>=0){
                    this.rutasRefs[indexUpdated].pctVal = 100
                    this.rutasRefs[indexUpdated].estatusMin = 26
                    this.rutasRefs[indexUpdated].estatusReal = 27
                  }

                }

              }
            }
          }
        // { id: 11, name: 'dashboard_pi_canales', pctVal: 83, estatusMin: 22, estatusReal: 23, path: '/dashboard/personal/aptitudes-b' },

        // { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 78, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' }, //depende de Pantalla anterior (tiene xp si/no)
        // { id: 13, name: 'dashboard_pi_curriculum', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        // { id: 14, name: 'dashboard_pi_sociales', pctVal: 90, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        // { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }

        // NO tiene EXP 
        // ----- { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 87, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' },  (SALTAR)
        // { id: 13, name: 'dashboard_pi_curriculum', pctVal: 87, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        // { id: 14, name: 'dashboard_pi_sociales', pctVal: 90, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        // { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }
        
				// SI tIENE EXP
				// { id: 12, name: 'dashboard_pi_experiencialaboral', pctVal: 87, estatusMin: 23, estatusReal: 24, path: '/dashboard/personal/aptitudes-c' },
				// NOTA: si el estatus es >24  checar si hay 1 o 3 registros de empleo si no lo tiene Restarle 5 al porcentaje actual 
        // { id: 13, name: 'dashboard_pi_curriculum', pctVal: 92, estatusMin: 24, estatusReal: 25, path: '/dashboard/personal/aptitudes-d' },
        // { id: 14, name: 'dashboard_pi_sociales', pctVal: 96, estatusMin: 25, estatusReal: 26, path: '/dashboard/personal/mediossociales' },
        // { id: 15, name: 'dashboard_pi_review', pctVal: 100, estatusMin: 26, estatusReal: 27, path: '/dashboard/personal/revision' }


          //NOTA el estatus es en base al perfil ahora en vez de la ruta de l questionario 
          indexEstatus  = this.rutasRefs.findIndex(item => item.estatusMin === this.profile_status)
          if (indexEstatus<0){ 
            indexEstatus = this.rutasRefs.findIndex(item => item.estatusMin >=  this.estatusx_USR.PREVIEW )
            if (indexEstatus<0){
              indexEstatus = 0
            }
          }
          newValuePct  = this.rutasRefs[indexEstatus].pctVal 

          // if (44===333){
          //   var rutaItem = null 
          //   indexRutaPersonal  = this.rutasRefs.findIndex(item => item.name === this.myRouteRef)
          //   if (indexRutaPersonal>=0){
          //     rutaItem = JSON.parse(JSON.stringify(this.rutasRefs[indexRutaPersonal]))
          //   }
          //    /* indexEstatus  = this.rutasRefs.findIndex(item => item.estatusReal === this.profile_status) */ 
          //   indexEstatus  = this.rutasRefs.findIndex(item => item.estatusMin === this.profile_status)
          //   if (indexRutaPersonal>=0) { 
          //     newValuePct  = this.rutasRefs[indexRutaPersonal].pctVal
          //     var indexMin  = this.rutasRefs.findIndex(item => item.estatusMin === this.profile_status)
          //     var recordMin = null
          //     if (indexMin>=0){
          //       recordMin = JSON.parse(JSON.stringify(this.rutasRefs[indexMin]))
          //       if (rutaItem!=null){
          //         if (recordMin.pctVal> rutaItem.pctVal){ 
          //           newValuePct  = recordMin.pctVal
          //         } else { 
          //           newValuePct  = rutaItem.pctVal
          //         }
          //       } else {
          //         newValuePct  = recordMin.pctVal
          //       }
          //     } else { 
          //       if (rutaItem!=null){
          //         newValuePct  = rutaItem.pctVal
          //       }
          //     }
          //   } else {
          //     if (indexEstatus<0){ 
          //       indexEstatus = this.rutasRefs.findIndex(item => item.estatusMin === parseInt(this.profile_status) )
          //     }
          //     newValuePct  = this.rutasRefs[indexEstatus].pctVal 
          //   }
          // }
      }

      // NOTA dev-sprint12 
      // Revisar que si el estatus es >= 23 si tiene Experiencia -> EMpleos Se modificaria el PCT de acuerdo AAAAAA 
      if (this.profile_status>=this.zstatUser.CANALES_EXPERIENCIA){
        if (this.current_profile._embedded.userPerfilLaboralFiltro){ 
          if (this.current_profile._embedded.userPerfilLaboralFiltro.length>0){ 
            var experieciaData = this.current_profile._embedded.userPerfilLaboralFiltro.filter(item=> item.categoriaId===14 && item.catalogo_id === 99)
            if (experieciaData.length>0) { //Si tiene experiencia  //Checamos los empleos (registros)
              var toRestProgress =  (this.current_profile._embedded.experienciaLaboral.length>0) ? 0 : 5 
              if ( this.profile_status>=this.zstatUser.LABORAL_EXPERIENCIA) { 
                newValuePct = newValuePct - toRestProgress
              }
            }
          }
        }
      }

      //Actualizamos el PAth a usar del menu (personal)
      var indexMnu = null
      indexMnu = ( newValuePct > 2 ) ?  this.rutasRefs.length -1  : 0 
      var myPathStr = this.rutasRefs[indexMnu].path
      this.questionarioPath = myPathStr
      this.profileProgress  = newValuePct
      if (newValuePct===100) { 
        if (this.profileComplete !== true ){ 
          this.profileComplete =true
        }
      } else { 
        this.profileComplete =false 
      }
      this.$forceUpdate()
    }
  },
  // Metodos del componente 
  beforeCreate () {
    this.$cannaDebug('-- dashlayout -- beforeCreate')
    this.initScreen = false 
  },
  created () {
    this.$cannaDebug('-- dashlayout -- created')

  },
  beforeMount () {
    // this.$cannaDebug('-- dashlayout --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- dashlayout -- mounted')

    if (this.profile_status >= 27){
      this.profileComplete = true
    }
    setTimeout(() => {
      this.initScreen = true
      this.updatePersonalProgress() 
      this.$forceUpdate()
    }, 100)
  },
  beforeUpdate () {
    // this.$cannaDebug('-- dashlayout -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- dashlayout -- updated')
  },
  activated () {
    this.$cannaDebug('-- dashlayout -- activated')
  },
  deactivated () {
    this.$cannaDebug('-- dashlayout -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- dashlayout -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- dashlayout -- destroyed')
  }
}
</script>
